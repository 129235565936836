import { createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import createAPIClient from '../mhboConfig'
import { RootState } from '..'
import { ITokenLoad, IAddFavoriteParams } from './types'
import { getFavorites } from './slice'
import { isTokenValid } from './helpers'
import { IFavorite } from 'mhbo-js'
import { apiClient } from '../mhboConfig'

export const loadToken = createAsyncThunk<
  ITokenLoad | void,
  void,
  { state: RootState }
>('user/loadToken', async (_, thunkAPI) => {
  let jwt = Cookies.get('jwt')
  const state = thunkAPI.getState()
  if (!jwt) {
    jwt = ''
  }
  if (jwt !== state.user.jwt) {
    createAPIClient(jwt)
    const userId = apiClient!.token.getUserId().userId || -1
    if (jwt !== '') {
      const favorites = await apiClient!.users.getFavorites(userId)
      thunkAPI.dispatch(getFavorites(favorites))
    }
    return {
      jwt,
      userId,
    }
  }
  return
})

export const addFavorite = createAsyncThunk<
  IFavorite | void,
  IAddFavoriteParams,
  { state: RootState }
>('user/addFavorite', async ({ id, type }, thunkAPI) => {
  const { user } = thunkAPI.getState()
  const { jwt, userId } = user
  if (!isTokenValid(jwt)) {
    alert('User not logged in')
    return
  }
  const newFavorite = await apiClient!.users.addFavorite(id, type, userId)
  return newFavorite
})

export const deleteFavorite = createAsyncThunk<
  IFavorite[] | void,
  number,
  { state: RootState }
>('user/addFavorite', async (id, thunkAPI) => {
  const { user } = thunkAPI.getState()
  const { jwt, userId, favorites } = user
  if (!isTokenValid(jwt)) {
    alert('User not logged in')
    return
  }
  const response = await apiClient!.users.deleteFavorite(id, userId)
  if (response.success) {
    const newFavorites = favorites.filter((fav: IFavorite) => fav.id !== id)
    return newFavorites
  }
  return
})
