import { createSlice } from '@reduxjs/toolkit'
import type { IUserState } from './types'
import { loadToken } from './actions'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFavorite } from 'mhbo-js'

// Define the initial state using that type
const initialState: IUserState = {
  favorites: [],
  jwt: '',
  userId: -1,
}

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    getFavorites: (state, action: PayloadAction<IFavorite[]>) => {
      state.favorites = action.payload
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(loadToken.fulfilled, (state, action) => {
      // Add user to the state array
      if (action.payload) {
        state.jwt = action.payload.jwt
        state.userId = action.payload.userId
      }
    })
  },
})

export const { getFavorites } = userSlice.actions

export default userSlice.reducer
