import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { updateSelectedLocation } from '../../../store/search/actions'
import { openSelectedUrl } from '../../../store/details/slice'

import { updateFirstLoad } from '../../../store/search/slice'
import ListingsView from './ListingsView'

const mapStateToProps = (state: RootState) => {
  return {
    currentSelection: state.details.currentSelection,
    selectedLocation: state.search.selectedLocation,
    selectedDetail: state.details.selectedDetail,
    isFirstLoad: state.search.isFirstLoad,
    recents: state.details.recents,
    showRecentsPanel: state.details.showRecentsPanel,
  }
}

const ConnectedListingsView = connect(mapStateToProps, {
  updateFirstLoad,
  updateSelectedLocation,
  openSelectedUrl,
})(ListingsView)

export default ConnectedListingsView
