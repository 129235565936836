import { FunctionComponent } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getSelectedLocationName } from '../../store/search/selectors'
import {
  updateSelectedLocation,
  updateTypedLocation,
} from '../../store/search/actions'

import { updateFirstLoad } from '../../store/search/slice'
import { showRecents } from '../../store/details/slice'
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

interface IProps {
  currentValue: string
}

const Breadcrumb: FunctionComponent<IProps> = ({ currentValue }: IProps) => {
  const isFirstLoad = useAppSelector((state) => state.search.isFirstLoad)

  const selectedLocationName = useAppSelector((state) =>
    getSelectedLocationName(state)
  )
  const initialSelectedLocation = useAppSelector(
    (state) => state.search.initialSelectedLocation
  )
  const showRecentsPanel = useAppSelector(
    (state) => state.details.showRecentsPanel
  )

  const dispatch = useAppDispatch()
  const listingsCount = useAppSelector(
    (state) => state.search.boundslistings.length
  )
  const recentsCount = useAppSelector((state) => state.details.recents.length)

  const handleChange = (_event: any, value: number) => {
    if ([0, 2].includes(value)) {
      dispatch(updateFirstLoad())
    }
    if ([0, 1].includes(value)) {
      dispatch(showRecents(false))
    }

    if (value === 0) {
      const newaddress = currentValue
        .split(',')
        .map((e: string) => e.trim())
        .join(', ')

      dispatch(updateTypedLocation(newaddress))
      dispatch(updateSelectedLocation(-1))
    }
    if (value === 1) {
      dispatch(updateFirstLoad(true))
      dispatch(updateSelectedLocation(initialSelectedLocation))
    }
    if (value === 2) {
      dispatch(showRecents(true))
    }
  }

  const AntTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: '#f59433',
    },
    '& .Mui-selected': {
      color: '#f59433 !important',
    },
  })

  return (
    <>
      <AntTabs
        value={
          selectedLocationName && isFirstLoad ? 1 : showRecentsPanel ? 2 : 0
        }
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
      >
        <Tab
          iconPosition="start"
          label={`Results (${listingsCount})`}
          icon={<SearchOutlinedIcon />}
          wrapped
          value={0}
          sx={{ textTransform: 'none' }}
        />
        {selectedLocationName ? (
          <Tab
            iconPosition="start"
            label={selectedLocationName}
            wrapped
            value={1}
            icon={<HolidayVillageOutlinedIcon />}
            sx={{ textTransform: 'none' }}
          />
        ) : null}
        {recentsCount > 0 ? (
          <Tab
            label={`Recents (${recentsCount})`}
            wrapped
            value={2}
            sx={{ textTransform: 'none' }}
          />
        ) : null}
      </AntTabs>

      <span>
        <span
          className="breadcrumb__upperlevelsButton"
          onClick={() => {}}
        ></span>
      </span>
      {selectedLocationName ? (
        <span>
          <span className="breadcrumb__upperlevelsButton" onClick={() => {}}>
            {}
          </span>
        </span>
      ) : null}
      {recentsCount > 0 ? (
        <span
          className="breadcrumb__upperlevelsButton"
          onClick={() => {}}
        ></span>
      ) : null}
    </>
  )
}

export default Breadcrumb
