import { Loader } from '@googlemaps/js-api-loader'
import GOOGLE_API from '../../constants/googleAPIConstants'
import { IListingForBoundsParams } from './types'
import { PAGE_COUNT } from '../../constants/paginationConstants'

export const filterListingsForBounds = async ({
  listings,
  bounds,
  filtered,
  triangle,
}: IListingForBoundsParams) => {
  if (bounds !== null && !isNaN(bounds.NE.lat)) {
    const { NE, SW } = bounds
    const ix = NE.lat
    const iy = NE.lng
    const ax = SW.lat
    const ay = SW.lng
    const loader = new Loader(GOOGLE_API)
    const google = await loader.load()

    const filteredListings = listings.filter((listing) => {
      const { latitude, longitude } = listing.address
      if (
        ix >= latitude &&
        latitude >= ax &&
        iy >= longitude &&
        longitude >= ay
      ) {
        if (filtered && triangle) {
          return triangle.some((polygon: any) => {
            return google.maps.geometry.poly.containsLocation(
              new google.maps.LatLng(latitude, longitude),
              polygon
            )
          })
        }
        return true
      }
      return false
    })
    return filteredListings
  }
  return listings
}

export const getSliceValues = (pageNumber: number) => {
  return {
    sliceEnd: PAGE_COUNT * pageNumber,
    sliceStart: PAGE_COUNT * (pageNumber - 1),
  }
}
