import { FunctionComponent } from 'react'
import { Breadcrumb } from '../../common-ui'
import { ISearchQuery } from '../../common-ui/interfaces'
import ListingHeader from './ListingHeader'
import { useAppSelector } from '../../../store/hooks'
import { getSelectedLocationName } from '../../../store/search/selectors'

interface IProps {
  currentQuery: ISearchQuery
}

const SearchForm: FunctionComponent<IProps> = ({ currentQuery }) => {
  const { entityType, listingTypeIds, location, isDealer } = currentQuery
  const currentLocation = location || ''
  const isFirstLoad = useAppSelector((state) => state.search.isFirstLoad)

  const selectedLocationName = useAppSelector((state) =>
    getSelectedLocationName(state)
  )
  const showRecentsPanel = useAppSelector(
    (state) => state.details.showRecentsPanel
  )
  return (
    <>
      <Breadcrumb currentValue={currentLocation} />
      {selectedLocationName && isFirstLoad ? null : showRecentsPanel ? null : (
        <ListingHeader
          entityType={entityType}
          listingTypeIds={listingTypeIds}
          isDealer={isDealer}
          currentLocation={currentLocation}
        />
      )}
    </>
  )
}

export default SearchForm
