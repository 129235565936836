import FilterPanel from './components/FilterPanel'
// CSS
import './styles/styles.scss'

// Router
import { BrowserRouter as Router } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import store from './store'

// Components
import PropertiesMap from './components/PropertiesMap/index'
import Sidebar from './components/Sidebar/Sidebar'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5KFP',
}
TagManager.initialize(tagManagerArgs)

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="container__appShell">
          <FilterPanel />
          <div className="container__mapAndSidebar">
            <div className="container__mapcolumn" id="map">
              <PropertiesMap />
            </div>
            <div className="container__sidebarcolumn" id="sidebar">
              <Sidebar />
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  )
}

export default App
