import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
  IListingsSearchParams,
  IUpdateBoundaryFilter,
  IUpdateInitialSelectedLocation,
  IUpdateInitialSelectedLocationParams,
  IUpdateListings,
  IUpdateListingsParams,
  IUpdateMapAxisAlignedBoundingBox,
  IUpdateMapAxisAlignedBoundingBoxParams,
  IUpdateSelectedLocation,
  IUpdateTypedLocation,
  IFetchListingsParams,
  IFilterListingsPaginatedParams,
} from './types'
import { filterListingsForBounds, getSliceValues } from './helpers'
import { MIN_RADIUS } from '../../constants/radiusConstants'
import { IMHBOListing } from 'mhbo-js'
import queryString from 'query-string'
import { apiClient } from '../mhboConfig'
import { updateFirstLoad, updateLatLng, updateBounds } from './slice'
import { loadToken } from '../user/actions'
import { updateCurrentSelection } from '../details/actions'
import { IEntityType, IMHBOSearchSummaryListing } from 'mhbo-js/lib/types'
import { ISearchQuery } from '../../components/common-ui/interfaces'
import getBaseUrl from '../../components/Sidebar/getBaseUrl'

export const updateListings = createAsyncThunk<
  IUpdateListings,
  IUpdateListingsParams,
  { state: RootState }
>(
  'search/updateListings',
  async (
    { searchIndex, listings, newParams = '', boundsFilterIndex },
    thunkAPI
  ) => {
    const state = thunkAPI.getState()
    const { search } = state
    const {
      listings: stateListings,
      bounds,
      filtered,
      triangle,
      selectedLocation,
      isFirstLoad,
      searchIndex: oldSearchIndex,
    } = search
    thunkAPI.dispatch(
      filterListingsForBoundsPaginated({
        listings,
        bounds,
        filtered,
        triangle,
        boundsFilterIndex,
      })
    )
    if (searchIndex === oldSearchIndex) {
      listings = [...stateListings, ...listings]
    }
    if (
      stateListings.length === 1 &&
      stateListings[0].id === selectedLocation &&
      isFirstLoad
    ) {
      listings = [
        ...stateListings,
        ...listings.filter((e) => e.id !== selectedLocation),
      ]
    }

    return { listings, newParams, searchIndex }
  }
)

export const updateActivePage = createAsyncThunk<
  number,
  number,
  { state: RootState }
>('search/updateActivePage', async (activePage, thunkAPI) => {
  const state = thunkAPI.getState()
  const { search } = state
  const { boundslistings } = search

  const { sliceStart, sliceEnd } = getSliceValues(activePage)
  thunkAPI.dispatch(
    updateCurrentSelection(boundslistings.slice(sliceStart, sliceEnd))
  )
  thunkAPI.dispatch(updateFirstLoad())

  return activePage
})

export const updateMapAxisAlignedBoundingBox = createAsyncThunk<
  IUpdateMapAxisAlignedBoundingBox,
  IUpdateMapAxisAlignedBoundingBoxParams,
  { state: RootState }
>(
  'search/updateMapAxisAlignedBoundingBox',
  async ({ NELat, NELng, SWLat, SWLng }, thunkAPI) => {
    const state = thunkAPI.getState()
    const { search } = state
    const { listings, filtered, triangle, boundsFilterIndex } = search
    const bounds = {
      NE: { lat: parseFloat(NELat), lng: parseFloat(NELng) },
      SW: { lat: parseFloat(SWLat), lng: parseFloat(SWLng) },
    }
    thunkAPI.dispatch(
      filterListingsForBoundsPaginated({
        listings,
        bounds,
        filtered,
        triangle,
        boundsFilterIndex: boundsFilterIndex + 1,
      })
    )

    return {
      bounds,
    }
  }
)

export const listingsSearch = createAsyncThunk<
  void,
  IListingsSearchParams,
  { state: RootState }
>('search/listingsSearch', async ({ params }, thunkAPI) => {
  try {
    const state = thunkAPI.getState()
    thunkAPI.dispatch(loadToken())
    const { search } = state
    const {
      searchIndex,
      lastParams,
      lastCenterLat,
      lastCenterLng,
      lastCenterRadius,
      boundsFilterIndex,
    } = search
    const newSearchIndex = searchIndex + 1
    const newBoundsIndex = boundsFilterIndex + 1

    if (lastParams === JSON.stringify(params)) {
      console.log('Params already match')
      return
    }
    if (lastCenterLat && !params.location) {
      params.location = `${lastCenterLat},${lastCenterLng}`
    }
    if (lastCenterRadius && !params.radius) {
      params.radius = lastCenterRadius
    }
    if (!params.location) return
    if (!params.entityType) {
      //query communitites only
      await Promise.all([
        fetchCommunities({ params, thunkAPI, newSearchIndex, newBoundsIndex }),
        fetchHomes({ params, thunkAPI, newSearchIndex, newBoundsIndex }),
      ])
    } else {
      if (params.entityType === '2') {
        //query communitites only
        await fetchCommunities({
          params,
          thunkAPI,
          newSearchIndex,
          newBoundsIndex,
        })
      } else {
        await fetchHomes({ params, thunkAPI, newSearchIndex, newBoundsIndex })
      }
    }

    return
  } catch (error) {
    console.log({ error })
  }
})

const fetchHomes = async ({
  params,
  thunkAPI,
  newSearchIndex,
  newBoundsIndex,
}: IFetchListingsParams) => {
  thunkAPI.dispatch(loadToken())

  const searchSummaryHomeListings = (await apiClient!.homes.searchSummaryV2(
    params
  )) as IMHBOSearchSummaryListing
  const { resultsCount, totalCount, results } = searchSummaryHomeListings

  await thunkAPI.dispatch(
    updateListings({
      searchIndex: newSearchIndex,
      listings: results,
      newParams: JSON.stringify(params),
      boundsFilterIndex: newBoundsIndex,
    })
  )
  if (resultsCount !== totalCount) {
    const totalPages = Math.ceil(
      (totalCount > 10000 ? 10000 : totalCount) / params.pageCount
    )
    const paramCalls = []
    for (let i = params.page + 1; i <= totalPages; i++) {
      paramCalls.push({ ...params, page: i })
    }
    await Promise.all(
      paramCalls.map(async (e) => {
        try {
          const paramListings = (await apiClient!.homes.searchSummaryV2(
            e
          )) as IMHBOSearchSummaryListing
          if (paramListings && paramListings.results) {
            await thunkAPI.dispatch(
              updateListings({
                searchIndex: newSearchIndex,
                listings: paramListings.results,
                newParams: JSON.stringify(params),
                boundsFilterIndex: newBoundsIndex,
              })
            )
          }
        } catch (err) {
          console.log({ err })
        }
      })
    )
  }
  return true
}

const fetchCommunities = async ({
  params,
  thunkAPI,
  newSearchIndex,
  newBoundsIndex,
}: IFetchListingsParams) => {
  thunkAPI.dispatch(loadToken())

  const searchSummaryResult = (await apiClient!.communities.searchSummaryV2({
    ...params,
    sellerTypeIds: [],
  })) as IMHBOSearchSummaryListing

  const { resultsCount, totalCount, results } = searchSummaryResult

  await filterAndUpdateCommunities({
    listings: results,
    params,
    thunkAPI,
    newSearchIndex,
    newBoundsIndex,
  })
  if (resultsCount !== totalCount) {
    const totalPages = Math.ceil(
      (totalCount > 10000 ? 10000 : totalCount) / params.pageCount
    )
    const paramCalls = []
    for (let i = params.page + 1; i <= totalPages; i++) {
      paramCalls.push({ ...params, page: i })
    }
    await Promise.all(
      paramCalls.map(async (e) => {
        try {
          const paramListings = (await apiClient!.communities.searchSummaryV2({
            ...e,
            sellerTypeIds: [],
          })) as IMHBOSearchSummaryListing

          if (paramListings && paramListings.results) {
            await filterAndUpdateCommunities({
              listings: paramListings.results,
              params,
              thunkAPI,
              newSearchIndex,
              newBoundsIndex,
            })
          }
        } catch (err) {
          console.log({ err })
        }
      })
    )
  }

  return true
}

const filterAndUpdateCommunities = ({
  params,
  listings,
  thunkAPI,
  newSearchIndex,
  newBoundsIndex,
}: IFetchListingsParams & { listings: IMHBOListing[] }) => {
  if (params.entityType && params.entityType === '2') {
    if (params.isDealer && params.isDealer === 'true') {
      listings = listings.filter((e) => e.entityType === IEntityType.Dealer)
    } else {
      listings = listings.filter((e) => e.entityType !== IEntityType.Dealer)
    }
  }
  return thunkAPI.dispatch(
    updateListings({
      searchIndex: newSearchIndex,
      listings,
      newParams: JSON.stringify(params),
      boundsFilterIndex: newBoundsIndex,
    })
  )
}

export const updateTypedLocation = createAsyncThunk<
  IUpdateTypedLocation,
  string,
  { state: RootState }
>('search/updateTypedLocation', async (typedLocation, thunkAPI) => {
  // Special cases
  let searchLocation = typedLocation.toLowerCase()
  if (
    searchLocation.includes('shelby') &&
    searchLocation.includes('mi') &&
    (searchLocation.includes('twp') || searchLocation.includes('township'))
  ) {
    searchLocation = 'Macomb County, MI, USA'
  } else {
    searchLocation = typedLocation
  }

  const response = await fetch(
    `${getBaseUrl()}/api/v1/map_data?q=${searchLocation}&polygon_geojson=1&format=json&limit=10&countrycodes=us,ca`
  )
  let streetMapData = {}
  let pointData = await response.json()
  pointData = pointData.filter(
    ({
      lat,
      lon,
      boundingbox: [sL, nL, wL, eL],
    }: {
      lat: string
      lon: string
      boundingbox: string[]
    }) => {
      const latitude = parseFloat(lat)
      const longitude = parseFloat(lon)
      return (
        parseFloat(sL) <= latitude &&
        latitude <= parseFloat(nL) &&
        parseFloat(wL) <= longitude &&
        parseFloat(eL) >= longitude
      )
    }
  )
  const nonPointsData = pointData.filter((e: any) => e.osm_type === 'relation')
  if (nonPointsData.length > 0) pointData = nonPointsData
  if (pointData.length > 0) {
    const data = pointData[0]
    const {
      lat,
      lon,
      geojson: { type, coordinates },
    } = data
    let { boundingbox } = data
    // allows mobile view to load results without map
    // eslint-disable-next-line no-restricted-globals
    const width = window.innerWidth > 0 ? window.innerWidth : screen.width
    // check if mobile to prevent double call on laptop in case map view venter is different from nominatim center
    if (width < 768) {
      const state = thunkAPI.getState()
      const {
        search: { lastParams },
      } = state
      let getParams = {}
      if (lastParams) {
        getParams = JSON.parse(lastParams)
      } else {
        const query = queryString.parse(window.location.search) as ISearchQuery
        getParams = query
      }
      const searchParams = {
        ...getParams,
        location: `${lat},${lon}`,
        page: 1,
        pageCount: 500,
        radius: MIN_RADIUS,
      }
      thunkAPI.dispatch(listingsSearch({ params: searchParams }))
      thunkAPI.dispatch(
        updateLatLng({ lat: parseFloat(lat), lng: parseFloat(lon) })
      )
    }
    streetMapData = { boundingbox, type, coordinates }
  }
  return { typedLocation, streetMapData }
})

export const updateInitialSelectedLocation = createAsyncThunk<
  IUpdateInitialSelectedLocation,
  IUpdateInitialSelectedLocationParams,
  { state: RootState }
>(
  'search/updateInitialSelectedLocation',
  async ({ selectedLocation, selectedEntityType }, thunkAPI) => {
    const { search } = thunkAPI.getState()
    thunkAPI.dispatch(loadToken())
    let { boundslistings } = search
    let { listings } = search
    const listingAlreadyPresent = boundslistings.find(
      (e: any) => e.id === selectedLocation
    )

    if (listingAlreadyPresent) {
      boundslistings = [
        listingAlreadyPresent,
        ...boundslistings.filter((e: any) => e.id !== selectedLocation),
      ]
      listings = [
        listingAlreadyPresent,
        ...listings.filter((e: any) => e.id !== selectedLocation),
      ]
    } else {
      // get listing and add
      let newListing = null

      if (selectedEntityType === IEntityType.Home) {
        newListing = (await apiClient!.homes.byIds([
          selectedLocation,
        ])) as IMHBOListing[]
      } else {
        newListing = (await apiClient!.communities.byIds([
          selectedLocation,
        ])) as IMHBOListing[]
      }

      boundslistings = [...newListing, ...boundslistings]
      listings = [...newListing, ...listings]
    }

    const { sliceStart, sliceEnd } = getSliceValues(1)
    thunkAPI.dispatch(
      updateCurrentSelection(boundslistings.slice(sliceStart, sliceEnd))
    )

    return {
      selectedLocation,
      boundslistings,
      listings,
      initialSelectedLocation: selectedLocation,
    }
  }
)

export const updateSelectedLocation = createAsyncThunk<
  IUpdateSelectedLocation,
  number,
  { state: RootState }
>('search/updateSelectedLocation', async (selectedLocation, thunkAPI) => {
  const { search } = thunkAPI.getState()

  let { boundslistings, listings, isFirstLoad } = search
  if (isFirstLoad) {
    const listingAlreadyPresent = boundslistings.find(
      (e: any) => e.id === selectedLocation
    )

    if (listingAlreadyPresent) {
      boundslistings = [
        listingAlreadyPresent,
        ...boundslistings.filter((e: any) => e.id !== selectedLocation),
      ]
    } else {
      // get listing and add
      let newListing = listings.find((e: any) => e.id === selectedLocation)
      if (newListing) boundslistings = [newListing, ...boundslistings]
    }
    const { sliceStart, sliceEnd } = getSliceValues(1)
    thunkAPI.dispatch(
      updateCurrentSelection(boundslistings.slice(sliceStart, sliceEnd))
    )
  }

  return { selectedLocation, boundslistings }
})

export const updateBoundaryFilter = createAsyncThunk<
  IUpdateBoundaryFilter,
  boolean,
  { state: RootState }
>('search/updateBoundaryFilter', async (filtered, thunkAPI) => {
  const { search } = thunkAPI.getState()
  const { listings, bounds, triangle, boundsFilterIndex } = search
  thunkAPI.dispatch(
    filterListingsForBoundsPaginated({
      listings,
      bounds,
      filtered,
      triangle,
      boundsFilterIndex: boundsFilterIndex + 1,
    })
  )

  return {
    filtered,
  }
})

export const filterListingsForBoundsPaginated = createAsyncThunk<
  void,
  IFilterListingsPaginatedParams,
  { state: RootState }
>(
  'search/filterListingsForBoundsPaginated',
  async (
    { listings, bounds, filtered, triangle, boundsFilterIndex },
    thunkAPI
  ) => {
    const { search, details } = thunkAPI.getState()
    const {
      boundsFilterIndex: stateFilterIndex,
      isFirstLoad,
      selectedLocation,
    } = search
    const { currentSelection } = details

    if (boundsFilterIndex >= stateFilterIndex) {
      let boundslistings = await filterListingsForBounds({
        listings: listings.slice(0, 100),
        bounds,
        filtered,
        triangle,
      })
      if (
        boundsFilterIndex > stateFilterIndex ||
        (currentSelection.length <= 1 && boundslistings.length > 0)
      ) {
        if (isFirstLoad && selectedLocation) {
          const listingAlreadyPresent = boundslistings.find(
            (e: any) => e.id === selectedLocation
          )

          if (listingAlreadyPresent) {
            boundslistings = [
              listingAlreadyPresent,
              ...boundslistings.filter((e: any) => e.id !== selectedLocation),
            ]
          } else {
            // get listing and add
            let newListing = listings.find(
              (e: any) => e.id === selectedLocation
            )
            if (newListing) boundslistings = [newListing, ...boundslistings]
          }
        }
        const { sliceStart, sliceEnd } = getSliceValues(1)
        await thunkAPI.dispatch(
          updateCurrentSelection(boundslistings.slice(sliceStart, sliceEnd))
        )
      }
      await thunkAPI.dispatch(
        updateBounds({ boundsFilterIndex, boundslistings })
      )

      if (listings.length > 100 && !filtered) {
        await thunkAPI.dispatch(
          filterListingsForBoundsPaginated({
            listings: listings.slice(100),
            bounds,
            filtered,
            triangle,
            boundsFilterIndex,
          })
        )
      }
    }
    return
  }
)
