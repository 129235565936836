import { FunctionComponent } from 'react'
interface IProps {
  color: string
  size: number
  onMouseOver?: () => void
  onClick?: () => void
  lat?: number
  lng?: number
  addHover: boolean
}
const CircularMarker: FunctionComponent<IProps> = ({
  color,
  size,
  onMouseOver,
  onClick,
  addHover,
}: IProps) => (
  <div
    className={
      addHover ? 'circularMarker__ContainerHover' : 'circularMarker__Container'
    }
    onMouseOver={onMouseOver}
    onClick={onClick}
  >
    <svg width={size / 1.5} height={size / 1.5}>
      <defs>
        <filter
          id="f1"
          x="-40%"
          y="-40%"
          width="180%"
          height="180%"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
          <feOffset dx="5" dy="5" result="offsetblur" />
          <feOffset dx="-5" dy="-3" result="offsetblur" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"
          />

          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle
        cx={size / 3}
        cy={size / 3}
        r={size / 3}
        fill={color}
        fillOpacity="0.2"
        className="circularMarker__Cover"
      />

      <circle
        r={size / 8}
        fill={color}
        z-index="55"
        cx={size / 3}
        cy={size / 3}
        stroke="white"
        strokeWidth="1"
        style={{ filter: 'url(#f1)' }}
      />
    </svg>
  </div>
)
export default CircularMarker
