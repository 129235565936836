import Share from '@mui/icons-material/Share'
import createGTM from '../../helpers/gtmevent'
import queryString from 'query-string'

import { FunctionComponent } from 'react'
import { updateModalShareState } from '../../store/search/slice'

import { ISearchQuery } from './interfaces'
import { useAppDispatch } from '../../store/hooks'

const Search: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const currentQuery: ISearchQuery = queryString.parse(window.location.search)
  return (
    <div
      className="share__wrapper"
      // tslint:disable-next-line: jsx-no-lambda
      onClick={() => {
        createGTM({
          category: 'SHARE',
          action: `Click SHARE incon`,
          label: `Click SHARE incon`,
          value: currentQuery,
        })
        dispatch(updateModalShareState(true))
      }}
    >
      <Share />
      <span className="ml-1">share</span>
    </div>
  )
}

export default Search
