import { FunctionComponent } from 'react'
interface IProps {
  pointCount: number
  onClick?: () => void
  lat?: number
  lng?: number
}

const ClusterMarker: FunctionComponent<IProps> = ({
  pointCount,
  onClick,
}: IProps) => {
  return (
    <span className="numberCircle" onClick={onClick}>
      <span>{pointCount}</span>
    </span>
  )
}
export default ClusterMarker
