import AdSense from 'react-adsense'
import { useAppSelector } from '../../../store/hooks'

const AdComponent = () => {
  const activePage = useAppSelector((state) => state.search.currentPageNumber)
  return (
    <div style={{ padding: '1rem' }}>
      <AdSense.Google
        mhboRenderkey={activePage}
        client="ca-pub-4780107190819740"
        slot="6208774806"
        style={{ display: 'block', height: '20rem' }}
        format=""
        responsive="true"
      />
    </div>
  )
}

export default AdComponent
